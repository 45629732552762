<template>
	<div>
		<t-modal v-model="isDetailOpen">
			<product-detail
				v-if="selectedProduct"
				:product="selectedProduct"
				@submited="isDetailOpen = false"
			/>
		</t-modal>

		<div
			v-if="categories.length > 0"
			class="border-bottom py-3 bg-white switch-bar"
		>
			<span
				v-for="(category, key) in categories"
				:key="key"
				class=" text-center ml-1 mr-1"
			>
				<button
					@click="addCategory(category.id)"
					:class="[
						'text-decoration-none p-1 rounded-full px-4 text-white cursor-pointer',
						selectedCategories.includes(category.id)
							? 'bg-blue-600'
							: 'bg-grey-600'
					]"
				>
					{{ category.label }}
				</button>
			</span>
		</div>

		<!-- osahan picks_today -->
		<div class="pick_today px-3 pb-3">
			<div class="row pt-3">
				<div v-if="filteredProducts.length == 0" class="col-12 p-2">
					<div
						class="p-4 text-center bg-white text-xl text-grey-400 shadow rounded"
					>
						AUCUN PRODUIT
					</div>
				</div>
				<div
					v-for="(product, key) in filteredProducts"
					:key="key"
					class="col-6 my-2"
				>
					<div class="list-card h-100 rounded position-relative">
						<div class="list-card-image">
							<a @click="openDetail(product)" class="text-dark ">
								<product
									:label="product.label"
									:prepration-period="
										product.preprationPeriod
									"
									:description="
										textTruncate(product.description)
									"
									:image="product.image"
									:prices="
										product.prices.map(el => ({
											id: el.id,
											isFavorit:
												user && user.likedPrices
													? user.likedPrices.includes(
															el.id
													  )
													: false,
											price: el.price,
											promotional: el.promoPrice
										}))
									"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Product from './Product';
import ProductDetail from './ProductDetail';

import { ApiGetProducts } from '@/tools/apiService';
import { stripHtml, textTruncate } from '@/tools/helpers';
import { mapGetters } from 'vuex';

export default {
	props: ['restaurantId'],
	data: () => ({
		isDetailOpen: false,
		selectedProduct: null,
		selectedCategories: [],
		products: []
	}),
	components: {
		Product,
		ProductDetail
	},
	mounted: function() {
		this.getProducts();
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
			deliveryHour: 'reduction/deliveryHour',
			intervalReduction: 'reduction/intervalReduction',
			commands: 'cart/commands',
			total: 'cart/total',
			hash: 'cart/hash',
			inviteCards: 'cart/inviteCards',
			command: 'cart/command'
		}),
		categories: function() {
			let categories = this.products.flatMap(el => el.category);
			let uniqueCategory = [...new Set(categories.map(el => el.tag))];
			return uniqueCategory.map(el =>
				categories.find(subEl => subEl.tag == el)
			);
		},
		filteredProducts: function() {
			if (this.selectedCategories.length > 0)
				return this.products.filter(el =>
					this.selectedCategories.includes(el.categoryId)
				);
			else return this.products;
		}
	},
	methods: {
		stripHtml,
		textTruncate,
		addCategory: function(categoryId) {
			if (this.selectedCategories.includes(categoryId))
				this.selectedCategories = this.selectedCategories.filter(
					el => el != categoryId
				);
			else this.selectedCategories.push(categoryId);
		},
		openDetail: function(product) {
			this.isDetailOpen = true;
			this.selectedProduct = product;
		},
		getProducts: function() {
			let restaurantId = this.restaurantId;
			ApiGetProducts({
				criterias: { restaurantId },
				withRelations: ['category', 'prices']
			}).then(reponse => {
				this.products = reponse.data.products;
			});
		}
	}
};
</script>

<style></style>
