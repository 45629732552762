<template>
	<div class="relative rounded bg-white shadow rounded">
		<div class="p-0">
			<div class="relative">
				<a
					@click.stop="toggleFavorit"
					class="absolute top-0 left-0 -m-1 -ml-2 font-weight-bold text-decoration-none"
					style="font-size: 1.5em;"
				>
					<i
						:class="[
							'icofont-heart p-2 shadow rounded-circle',
							pricesClone[0].isFavorit
								? 'bg-white text-magenta'
								: 'bg-white text-grey-500'
						]"
					/>
				</a>
				<div
					v-if="preprationPeriod"
					class="member-plan -mb-4 mr-1 absolute bottom-0 right-0"
				>
					<span class="badge badge-time ">{{
						preprationPeriod
					}}</span>
				</div>
				<div
					class="item-img w-full h-24 mb-3 bg-cover rounded"
					:style="
						`background-image: url(${$constants.STORAGE_PATH}${image})`
					"
				></div>
			</div>
			<h6 class="ml-3 font-weight-bold ">
				{{ label }}
			</h6>
			<p class="ml-3 text-grey-600 text-xs overflow-y-auto break-words">
				{{ textTruncate(description, 50) }}
			</p>
			<div class="d-flex align-items-end w-full">
				<h6
					v-if="pricesClone.length == 1"
					class="price ml-3 text-black"
				>
					<span
						v-if="
							pricesClone[0].price != pricesClone[0].promotional
						"
						class="line-through text-red-900"
					>
						{{ `${pricesClone[0].price} ${currency}` }}
						<br />
					</span>
					<span>
						{{ `${pricesClone[0].promotional} ${currency}` }}
					</span>
				</h6>
				<h6
					v-else-if="pricesClone.length > 1"
					class="price ml-3 text-black"
				>
					{{
						`${pricesClone
							.map(el => el.price)
							.join(' / ')} ${currency}`
					}}
				</h6>
				<h6 v-else class="price ml-3 text-black">XX {{ currency }}</h6>
				<button
					class="btn btn-dark btn-sm ml-auto"
					style="font-size: 1.5rem;border-radius: 7px 0px;padding: 0 0.5rem;"
				>
					+
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { textTruncate } from '@/tools/helpers';
import { ApiFavoritPrice } from '@/tools/apiService';
export default {
	props: ['label', 'description', 'preprationPeriod', 'image', 'prices'],
	data: () => ({
		pricesClone: [{}]
	}),
	mounted: function() {
		this.pricesClone = this.prices;
	},
	watch: {
		prices: function() {
			this.pricesClone = this.prices;
		}
	},
	computed: mapGetters({
		currency: 'lang/getCurrency'
	}),
	methods: {
		textTruncate,
		toggleFavorit: function() {
			ApiFavoritPrice(this.pricesClone[0].id).then(() => {
				this.pricesClone[0].isFavorit = !this.pricesClone[0].isFavorit;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.badge-time {
	background: #fff;
	color: gray;
	font-size: 11px;
	border-radius: 1rem !important;
	border: 1px solid gray;
	-webkit-box-shadow: 10px 10px 19px 0px rgba(0, 0, 0, 0.12);
	-moz-box-shadow: 10px 10px 19px 0px rgba(0, 0, 0, 0.12);
	box-shadow: 10px 10px 19px 0px rgba(0, 0, 0, 0.12);
}
</style>
