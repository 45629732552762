var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative rounded bg-white shadow rounded"},[_c('div',{staticClass:"p-0"},[_c('div',{staticClass:"relative"},[_c('a',{staticClass:"absolute top-0 left-0 -m-1 -ml-2 font-weight-bold text-decoration-none",staticStyle:{"font-size":"1.5em"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavorit.apply(null, arguments)}}},[_c('i',{class:[
						'icofont-heart p-2 shadow rounded-circle',
						_vm.pricesClone[0].isFavorit
							? 'bg-white text-magenta'
							: 'bg-white text-grey-500'
					]})]),(_vm.preprationPeriod)?_c('div',{staticClass:"member-plan -mb-4 mr-1 absolute bottom-0 right-0"},[_c('span',{staticClass:"badge badge-time "},[_vm._v(_vm._s(_vm.preprationPeriod))])]):_vm._e(),_c('div',{staticClass:"item-img w-full h-24 mb-3 bg-cover rounded",style:(("background-image: url(" + (_vm.$constants.STORAGE_PATH) + _vm.image + ")"))})]),_c('h6',{staticClass:"ml-3 font-weight-bold "},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('p',{staticClass:"ml-3 text-grey-600 text-xs overflow-y-auto break-words"},[_vm._v(" "+_vm._s(_vm.textTruncate(_vm.description, 50))+" ")]),_c('div',{staticClass:"d-flex align-items-end w-full"},[(_vm.pricesClone.length == 1)?_c('h6',{staticClass:"price ml-3 text-black"},[(
						_vm.pricesClone[0].price != _vm.pricesClone[0].promotional
					)?_c('span',{staticClass:"line-through text-red-900"},[_vm._v(" "+_vm._s(((_vm.pricesClone[0].price) + " " + _vm.currency))+" "),_c('br')]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(((_vm.pricesClone[0].promotional) + " " + _vm.currency))+" ")])]):(_vm.pricesClone.length > 1)?_c('h6',{staticClass:"price ml-3 text-black"},[_vm._v(" "+_vm._s(((_vm.pricesClone .map(function (el) { return el.price; }) .join(' / ')) + " " + _vm.currency))+" ")]):_c('h6',{staticClass:"price ml-3 text-black"},[_vm._v("XX "+_vm._s(_vm.currency))]),_c('button',{staticClass:"btn btn-dark btn-sm ml-auto",staticStyle:{"font-size":"1.5rem","border-radius":"7px 0px","padding":"0 0.5rem"}},[_vm._v(" + ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }