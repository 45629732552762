var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-modal',{model:{value:(_vm.isDetailOpen),callback:function ($$v) {_vm.isDetailOpen=$$v},expression:"isDetailOpen"}},[(_vm.selectedProduct)?_c('product-detail',{attrs:{"product":_vm.selectedProduct},on:{"submited":function($event){_vm.isDetailOpen = false}}}):_vm._e()],1),(_vm.categories.length > 0)?_c('div',{staticClass:"border-bottom py-3 bg-white switch-bar"},_vm._l((_vm.categories),function(category,key){return _c('span',{key:key,staticClass:" text-center ml-1 mr-1"},[_c('button',{class:[
					'text-decoration-none p-1 rounded-full px-4 text-white cursor-pointer',
					_vm.selectedCategories.includes(category.id)
						? 'bg-blue-600'
						: 'bg-grey-600'
				],on:{"click":function($event){return _vm.addCategory(category.id)}}},[_vm._v(" "+_vm._s(category.label)+" ")])])}),0):_vm._e(),_c('div',{staticClass:"pick_today px-3 pb-3"},[_c('div',{staticClass:"row pt-3"},[(_vm.filteredProducts.length == 0)?_c('div',{staticClass:"col-12 p-2"},[_c('div',{staticClass:"p-4 text-center bg-white text-xl text-grey-400 shadow rounded"},[_vm._v(" AUCUN PRODUIT ")])]):_vm._e(),_vm._l((_vm.filteredProducts),function(product,key){return _c('div',{key:key,staticClass:"col-6 my-2"},[_c('div',{staticClass:"list-card h-100 rounded position-relative"},[_c('div',{staticClass:"list-card-image"},[_c('a',{staticClass:"text-dark ",on:{"click":function($event){return _vm.openDetail(product)}}},[_c('product',{attrs:{"label":product.label,"prepration-period":product.preprationPeriod,"description":_vm.textTruncate(product.description),"image":product.image,"prices":product.prices.map(function (el) { return ({
										id: el.id,
										isFavorit:
											_vm.user && _vm.user.likedPrices
												? _vm.user.likedPrices.includes(
														el.id
												  )
												: false,
										price: el.price,
										promotional: el.promoPrice
									}); })}})],1)])])])})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }